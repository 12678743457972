import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { Subscription } from "rxjs";
import { BidSessionDetailService } from "@app/admin/bid-sessions/bid-session-detail.service";
import { Const } from "@const/Const";
import { SendASAPDlg } from "@app/admin/carrier-sale-detail/component/carriers/send-asap";
import { AddCarrier } from "@app/admin/carrier-sale-detail/component/carriers/add-carrier";
import { AddCarrierPool } from "@app/admin/carrier-sale-detail/component/carriers/add-carrier-pool";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
// import { AddRouteComponent } from "../add-route";

@Component({
  selector: "bid-session-outreach",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class BidSessionOutreach extends BaseComponent {
  @Input() bidSessionId: string;
  @Input() currentCarrierIds: string[];
  bid: any;
  private bidDetailSubscription: Subscription;

  public tabSelected: number = 0;
  private tabIndexs = {
    'matched-carriers': 0,
    'external-posting': 1,
  }
  
  constructor(private bidSessionDetailService: BidSessionDetailService, private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.bidSessionDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
    });
  }

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }

  get isMatchedCarriersTab() {
    return this.tabSelected === this.tabIndexs['matched-carriers'];
  }

  get isExternalPostingTab() {
    return this.tabSelected === this.tabIndexs['external-posting'];
  }

  confirmSendAsap() {
    DialogService.openDialog1(SendASAPDlg, {
      nzComponentParams: {
        bidId: this.bid.id,
        type: 'sendASAP',
        onSave: () => this.api.POST(Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/update-queue`)),
        onDone: (data) => {
          this.carrierSaleDetailService.emitGetDataBid();
          this.showSuccess('Requeue successfull');
        }
      },
    });
  }

  addRoute() {
    return this.router.navigate([Const.routeAdminBidSessions, this.bidSessionId, 'add-routes']);
  }

  private addCarrierToSession(listIds: string[]) {
    const url = `${Const.APIURI_BID_SESSIONS}/${this.bidSessionId}/candidates`;
    return this.api.POST(url, {carrierIds: listIds}).toPromise();
  }

  openAddCarrierModal() {
    DialogService.openFormDialog1(AddCarrier, {
      nzComponentParams: {
        model: this.bid,
        closeOnSuccess: true,
        currentCarrierIds: this.currentCarrierIds,
        submit: async (carrierIds: string[]) => this.addCarrierToSession(carrierIds),
        updateSuccess: (resp) => {
        },
      },
      nzClassName: "send-mail-form modal-xl",
    });
  }
 
  openAddCarrierPoolModal() {
    DialogService.openFormDialog1(AddCarrierPool, {
      nzComponentParams: {
        model: this.bid,
        closeOnSuccess: true,
        updateSuccess: (resp) => {

        },
      },
      nzClassName: "add-carrier-pool-form modal-xl",
    });
  }
}