<div class="carrier-selection">
  <div>
    <div class="box-content top20">
      <div class="carrier-tab">
        <nz-tabset [(nzSelectedIndex)]="tabSelected" [nzType]="'line'" [nzSize]="'small'">
          <nz-tab nzTitle="Invite Bid Automation"></nz-tab>
          <nz-tab nzTitle="3rd Party Post"></nz-tab>
        </nz-tabset>

        <div class="g-action">
          <button nz-button nzType="primary" (click)="confirmSendAsap()">
            <span nz-icon nzType="send" nzTheme="outline"></span>
            Send ASAP
          </button>
          <button nz-button nzType="primary" (click)="addRoute()">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
            Add Route
          </button>
          <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="menu">
            Add Carriers
            <span nz-icon nzType="down"></span>
          </button>
          <button nz-button nzType="primary">
            <span nz-icon nzType="setting" nzTheme="outline"></span>
            Setting
          </button>
        </div>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="openAddCarrierModal()">
              <span nz-icon nzType="plus" nzTheme="outline"></span>
              <span>Add Carrier</span>
            </li>
            <li nz-menu-item (click)="openAddCarrierPoolModal()">
              <span nz-icon nzType="plus" nzTheme="outline"></span>
              <span>Add Carrier Pools</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>

      <ng-container *ngIf="isMatchedCarriersTab">
        <div>
          <bid-session-wave></bid-session-wave>
        </div>
      </ng-container>
      
      <ng-container *ngIf="isExternalPostingTab">
        <div>
          <bulk-external-posting></bulk-external-posting>
        </div>
      </ng-container>
     
    </div>
  </div>
</div>
