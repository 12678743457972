import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseCarrierSaleTabResult } from "../BaseTabResult";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { CommLogList } from "@app/admin/components/comm-log";
import _ from "underscore";

@Component({
  selector: '[carrier-sale-tab-no-response]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class CarrierSaleTabNoResponse extends BaseCarrierSaleTabResult {
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }
  @Input() totalCount: number = 0;
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  public limit: number = Const.PAGINATION_LIMIT;
  public pageIndex: number = 1;
  
  ngOnInit() {
    this.loadFilter()
  }

  ngOnChanges() {
    this.loadFilter();
  }

  loadFilter() {
    this.carrierSaleDetailService.getCarrierTabFilter().subscribe(currentFilter => {
      if (currentFilter) {
        this.pageIndex = currentFilter.pageIndex;
        this.limit = currentFilter.limit;
      }
    });
  }
  
  onChangePage(value: number) {
    this.pageChange.emit(value);
  }

  $documentColor = (isPassed: boolean) => {
    return isPassed ? 'success' : 'default';
  }

  $goHighWayColor = (status: string) => {
    switch (status) {
      case 'pass':
      case 'partial_pass':
        return 'success';
      case 'fail':
        return 'red';
      default:
        return '';
    }
  }
  $goHighWayStatus = (status: string) => {
    switch (status) {
      case 'partial_pass':
        return 'Partial Pass';
      case 'pass':
        return 'Pass';
      case 'incomplete':
        return 'Incomplete';
      case 'fail':
        return 'Fail';
      default:
        return 'N/A';
    }
  }

  $asSentStatusColor = (status = "") => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'gray';
      case 'sent':
      case 'success':
      case 'unknown':
        return 'green';
      case 'failed':
      case 'invalid':
      case 'unsubscribed':
        return 'red';
      case 'delivered':
      case 'opened':
      case 'clicked':
        return 'blue';
      case 'undelivered':
      case 'unreachable':
        return 'orange';
      default:
        return '';
    }
  }

  $asSentStatusText = (status = "") => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'Pending';
      case 'sent':
      case 'unknown':
        return 'Sent';
      case 'failed':
        return 'Failed';
      case 'success':
        return 'Success';
      case 'delivered':
        return 'Delivered';
      case 'opened':
        return 'Opened';
      case 'clicked':
        return 'Clicked';
      case 'unsubscribed':
        return 'Unsubscribed';
      case 'undelivered':
        return 'Undelivered';
      case 'invalid':
        return 'Invalid';
      case 'unreachable':
        return 'Unreachable';
      default:
        return status;
    }
  };

  $shouldShowHistory = (item) => {
    return item?.logId
  }

  onBtnHistoryStatus(item) {
    const { commlogHistories } = item;
    let allHistories = commlogHistories.map(it => {
      return {
        ...it,
        ...(it.contact ?? {}),
      }
    })
    allHistories = allHistories.sort(function (a, b) {
      let bDate = new Date(b.when)
      let aDate = new Date(a.when)
      return Number(bDate) - Number(aDate)
    });

    if (!allHistories.length) return;
    DialogService.openFormDialog1(CommLogList, {
      nzComponentParams: {
        model: { histories: _.uniq(allHistories, it => it.logId) },
        closeOnSuccess: true
      },
      nzClassName: "comm-log-form modal-no-padding modal-xl",
    });
  }

  onBtnChangSendBidQueueStatus(item) {
    this.confirmYesNo(`This carrier may not response because they declined or never response this lane before!<br> Are you sure to add to invitation queue?`, () => {
      this.api.POST(Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/active-send-bid-queue`), { carrierId: item.carrierId }).subscribe(
        (resp) => {
          this.onReload.emit();
          this.showSuccess('Update successfull');
        },
        (err) => {
          this.showErr(err);
        }
      );
    });
  }

  getCancelMessage(item) {
    return `Cancelled send bid due to ${item.sendBidQueueNote} with same lane in the past.`;
  }

  isNeverBid(item) {
    return item?.metadata?.carrier?.neverBidBefore;
  }
}