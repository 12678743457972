<div class="more-info">
  <div nz-row [nzGutter]="16">
    <div nz-col [nzSpan]="8">
      <!-- profile -->
      <div class="more-info-item">
        <div class="item-title">Profile</div>
        <div *ngIf="isLoadingStatistic"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        <div *ngIf="!isLoadingStatistic" nz-row [nzGutter]="16" class="top10">
          <div nz-col [nzSpan]="12">
            <div nz-row *ngFor="let item of biddingStatistic.zipcodes || []; let i=index">
              <div class="statistic-item" [ngClass]="getStatisticItemRank(i)">{{item.statistic}}</div>
            </div>
          </div>
          <div nz-col [nzSpan]="12">
            <div nz-row *ngFor="let item of biddingStatistic.vehicles || []; let i=index">
              <div class="statistic-item" [ngClass]="getStatisticItemRank(i)">{{item.statistic}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- verify info -->
      <div class="more-info-item">
        <div class="item-title">Verify</div>
        <div class="item-content">
          <div class="flex">
            <div>Highway:</div>
            <div class="left5"><nz-tag [nzColor]="$goHighWayColor(candidate?.goHightWayStatus)">{{ $goHighWayStatus(candidate?.goHightWayStatus) }}</nz-tag></div>
          </div>
          <div class="flex" style="margin-top: 4px;">
            <div>Documents:</div>
            <div class="left5">{{ candidate?.documents?.isPassed ? 'Passed': 'Not Verified' }}</div>
          </div>
        </div>
      </div>
      <!-- carrier rating -->
      <div class="more-info-item" *ngIf="shouldShowCarrierRating">
        <div class="item-title">Carrier Rating</div>
        <div class="item-content">
          <div (click)="onBtnViewDetailRating()" style="color: #0069da; cursor: pointer; text-decoration: underline;">Detail</div>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="8">
      <!-- working history -->
      <div class="more-info-item">
        <div class="item-title">Working History</div>
        <div class="item-content" *ngIf="isLoadingWorkingHistory"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        <div class="item-content" *ngIf="!isLoadingWorkingHistory">
          <div *ngIf="workingHistory?.canceledAfterBooked?.length > 0" class="flex">
            <div>Canceled after booked:</div>
            <div class="flex left5">
              <a *ngFor="let jobItem of workingHistory?.canceledAfterBooked || []; let i=index" [href]="gotoDispatch(jobItem)" target="_blank" class="left5 text-warning">
                {{ showCanceledItemRouteCode(jobItem, i) }}
              </a>
            </div>
          </div>
          <div *ngIf="workingHistory?.lateJobCount > 0">PU/DO Late: {{ showLateJobCount() }}</div>
          <div *ngIf="workingHistory?.notUserLiveTrackingCount > 0">Not Use Live Tracking: {{ showNotUseLiveTrackingCount() }}</div>
          <div *ngIf="workingHistory?.lastCompletedRoute?.length > 0" class="flex">
            <div>Last Completed:</div>
            <div class="flex left5">
              <a *ngFor="let jobItem of workingHistory?.lastCompletedRoute || []; let i=index" [href]="gotoDispatch(jobItem)" target="_blank" class="left5">
                {{ showLastCompletedRouteCode(jobItem, i) }}
              </a>
            </div>
          </div>
          <div *ngIf="noDataWorkingHistory">N/A</div>
        </div>
      </div>
      <!-- avaiability -->
      <div class="more-info-item">
        <div class="item-title">Fraud Detection</div>
        <div class="item-content" *ngIf="isLoadingSameDay"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        <div class="item-content" *ngIf="!isLoadingSameDay">
          <div *ngIf="listJobSameDayAndEquipment.length" class="flex">
            <div class="text-warning">Assigned to Same day:</div>
            <div class="flex left5">
              <a *ngFor="let jobItem of listJobSameDayAndEquipment || []; let i=index" [href]="gotoDispatch(jobItem)" target="_blank" class="left5 text-warning">{{ showSameDayItemRouteCode(jobItem, i) }}</a>
            </div>
          </div>
          <div *ngIf="noDataFraudDetection">N/A</div>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="8">
      <!-- Source -->
      <div class="more-info-item">
        <div class="item-title">Source</div>
        <div class="item-content">
          <div>{{ candidate?.source?.category }}</div>
          <div *ngIf="candidate?.source?.desc"> {{ candidate?.source?.desc }}</div>
          <div *ngIf="whyAdded(candidate)">{{ whyAdded(candidate) }}</div>
        </div>
      </div>
      <!-- Bidding history -->
      <div class="more-info-item">
        <div class="item-title">Bidding History</div>
        <div class="item-content">
          <!-- base rate -->
          <div *ngIf="hasBasePrice">{{ showBasePrice() }}</div>
          <div *ngIf="isSentInvitation">
            Invitation <span class="item-desc left5">- {{ showInvitationSentTime() }}</span>
          </div>
          <!-- counter -->
          <div *ngIf="hasCounterBidHistory()">
            <div *ngFor="let counter of candidate.bidAnswers; let i=index">
              {{ showBidCounterAnswer(counter, i) }}
              <span class="item-desc left5">- {{showBidCounterAnswerWhenBy(counter)}}</span>
            </div>
          </div>
          <!-- sent lost bid -->
          <div *ngIf="isSentLostBid" class="flex">
            Lost bid sent
            <div class="item-desc left5">
              <warp-when-by [model]="candidate?.sentLostBid" [viewTemplate]="tplSentLostBid"></warp-when-by>
              <ng-template #tplSentLostBid let-hasData="hasData" let-displayInfo="displayInfo">
                <span>- {{displayInfo.createdWhen}}</span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>