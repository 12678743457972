<div class="section-result">
  <nz-table
    [nzScroll]="{ x: '1200px', y: '500px' }"
    nzBordered="true"
    [nzData]="candidates"
    [nzTotal]="totalCount" [nzFrontPagination]="false"
    [nzShowPagination]="totalCount > candidates.length && !hasBidSelected"
    [nzPageSize]="limit"
    [nzPageIndex]="pageIndex"
    [nzLoading]="isLoading"
    [nzLoadingIndicator]="tplLoading"
    [nzNoResult]="tplNoData"
    (nzPageIndexChange)="onChangePage($event)"
  >
    <ng-template #tplNoData>
      <div *ngIf="!isLoading && !hasBidSelected" class="nodata">
        <i nz-icon nzType="search" nzTheme="outline"></i>No data
      </div>
    </ng-template>
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-template>

    <thead>
      <tr>
        <th>
          <ng-container *ngIf="hasBidSelected">
            <a (click)="onExpand()">Expand All Carrier Bids</a>
          </ng-container>
          <ng-container *ngIf="!hasBidSelected">
            <a>View All Carrier Bids</a><br/>
            Sort by default
          </ng-container>
        </th>
        <th *ngFor="let job of jobs">
          <div class="g-job">
            <div class="job-code">{{ job.code }}</div>
            <div class="job-status">
              <ng-container *ngIf="getBidStatus(job) === 'warning'">
                <span nz-icon nzType="warning" nzTheme="fill" class="danger"></span>
              </ng-container>
              <ng-container *ngIf="getBidStatus(job) === 'completed'">
                <span nz-icon nzType="check-circle" nzTheme="fill" class="success"></span>
              </ng-container>
            </div>
          </div>
          <div class="g-desc">
            <div class="pickup-date">
              {{ $formatPickupDate(job?.pickupTime) }}<br/>
            </div>
            <ng-container *ngIf="!job?.assignedCarrier?.id">
              <a (click)="onViewBid(job)">{{ job?.countHasBids }} bids</a>
            </ng-container>
            <div class="assigned" *ngIf="job?.assignedCarrier?.id">
              Assigned
            </div>
            
            <span nz-icon nzType="caret-down" nzTheme="outline" nz-dropdown [nzDropdownMenu]="actionRoute"></span>
            <nz-dropdown-menu #actionRoute="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li nz-menu-item (click)="sortBidByRoute(job)">Sort bids by this route</li>
                <li nz-menu-item (click)="removeRoute(job)" *ngIf="isShowRemoveRoute(job)">Remove route from session</li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </th>
      </tr>
    </thead>

    <tbody *ngIf="!hasBidSelected">
      <tr *ngFor="let item of candidates">
        <td nzLeft>
          <div class="name">{{ getCarrierName(item) }}</div>
          <div class="small" *ngIf="getAssignedJob(item)">Assigned to {{ getAssignedJob(item) }} routes</div>
          <div class="status">
            <ng-container *ngIf="item.status != 1">
              <nz-tag nzColor="warning">{{$asCarrierStatusText(item.status)}}</nz-tag>
            </ng-container>
            <ng-container *ngIf="item.status == 1">
              <nz-tag nzColor="success">Active</nz-tag>
            </ng-container>
            <span nz-icon nzType="caret-down" nzTheme="outline" nz-dropdown [nzDropdownMenu]="actionCarrier"></span>
            <nz-dropdown-menu #actionCarrier="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li nz-menu-item (click)="sortBidsByCarrier(item)">Sort bids by this carrier</li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </td>
        <td *ngFor="let job of jobs">
          <div class="g-price">
            <div class="price">
              {{ getPrice(item, job) }}
              <ng-container *ngIf="isAssigned(item, job)">
                <br/>
                <span class="success">Assigned</span>
              </ng-container>
            </div>
            <ng-container *ngIf="!isAssigned(item, job) && getPrice(item, job)">
              <div class="action" (click)="onBtnAcceptBid(item, job)">
                <img src="/assets/svg/assign.svg" class="clickable" style="width: 22px">
              </div>
            </ng-container>
          </div>
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="hasBidSelected">
      <tr>
        <td [colSpan]="countColumn">
          <bid-sesion-carrier-result [bid]="bidSelected"></bid-sesion-carrier-result>
        </td>
      </tr>
    </tbody>

  </nz-table>
</div>