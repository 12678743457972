import { BaseComponent } from '@abstract/BaseComponent';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Const } from '@const/Const';
import { Job } from '@wearewarp/types/data-model';

@Component({
  selector: 'bid-session-route-list-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class RouteListViewComponent extends BaseComponent {
  private _routes: Job[] = [];

  @Input() set routes(val: Job[]) {
    this._routes = val.map(job => {
      return { ...job, ...this.createHyperLinkForJob(job) };
    });
  }

  @Input() settings: any = {};

  get routes() {
    return this._routes;
  }

  @Output() removeRoute: EventEmitter<any> = new EventEmitter<any>();

  onRemoveRoute(job) {
    this.removeRoute.emit(job);
  }

  createHyperLinkForJob(job: Job) {
    const shipments = job.shipments || [];
    let firstShipment = shipments[0];
    let hyperLinkUrl = `${Const.routeAdminDispatchList}/${job.id}`;
    const clients = job.clients || [];
    const clientName = clients?.[0]?.name || 'Customer N/A';
    let hyperLinkText = `${clientName} / ${this.getMyWarpId(firstShipment)}`;
    if (shipments.length > 1) {
      hyperLinkText += ` (+${shipments.length - 1})`;
    }
    hyperLinkText += ` / Route ${job.code}`;
    return { hyperLinkText, hyperLinkUrl };
  }

  private getMyWarpId(data) {
    return data?.code || data?.warpId || data?.id || data?._id || 'Shipment N/A';
  }

  get isAutoAddRoute() {
    return this.settings?.isAutoAddRoute || false;
  }
}