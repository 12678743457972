import { Component } from "@angular/core";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { BaseCarrierSaleTabResult } from "../BaseTabResult";
import { DialogService } from "@dialogs/dialog.service";
import { CounterForCarrierComponent } from "@app/admin/carrier-bids/components/counter-for-carrier/counter-for-carrier.component";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { EditCounterComponent } from "@app/admin/carrier-bids/components/edit-counter/edit-counter.component";
import { AssignCarrier } from "@app/admin/carrier-bids/components/assign-carrier";
import { InputHelper } from "@services/input-helper";
import { MasterData } from "@services/master.data";
import { BizUtil } from "@services/biz";


@Component({
  selector: '[carrier-sale-tab-response-received]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class CarrierSaleTabResponseReceived extends BaseCarrierSaleTabResult {
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  bidSessions: {
    key: string,
    candidates: any[],
    totalCount: number,
    data?: any,
    desc?: string,
    expand: boolean
  }[] = [];
  biddingScores: {[key: string]: number} = {};    // bidding score cho từng carrier
  bestScoreCarrierId: string = '';
  bestCandidateId: string = '';
  needGroupBySession: boolean = false;
  expandSet = new Set<number>();

  ngOnInit() {
    super.ngOnInit();
    // Khi nào update lại candidate thì mới cần tính lại score
    this.subscription.add(this.carrierSaleDetailService.carriers$.subscribe((carriers) => {
      this.fetchCarriersBiddingScore(carriers)
      this.needGroupBySession = true;
    }))
  }

  ngOnChanges() {
    super.ngOnChanges();
    // Chỉ khi fetch listCandidate mới thì mới cần group lại, tránh xử lý nhiều lần khi chỉ buildData trên UI
    if (this.needGroupBySession) {
      this.needGroupBySession = false
      this.handleGroupCandidatesBySession();
    }
    else {
      this.updateSessionCandidatesWithoutGroup();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get hasBasePrice() {
    return this.bid?.basePrice ? true : false;
  }

  handleGroupCandidatesBySession() {
    this.bidSessions = [];
    let allCandidates = [...this.candidates].filter(it => !this.isCarrierRefusedBid(it));
    let bidSession = [...(this.bid?.sessionChanges || []).filter(it => it.type === 'vehicleTypeUpdated')].reverse();
    if (bidSession.length == 0) {   // Nếu bid không có thay đổi thì đưa hết về newest
      this.bidSessions.push({
        key: 'newest',
        candidates: allCandidates,
        totalCount: allCandidates.length,
        expand: true
      })
    }
    else {
      // đưa về các session
      let currentSessionId = this.bid?.sessionId;
      let newestCandidate = allCandidates.filter(it => it.sessionId === currentSessionId);
      let groupedIds = newestCandidate.map(it => it.id) || [];
      let newestEquipment = this.getEquipment(this.bid?.job?.requiredVehicle);
      this.bidSessions.push({
        key: 'newest',
        candidates: newestCandidate,
        totalCount: newestCandidate.length,
        desc: newestEquipment ? `New Equipment ${newestEquipment}` : '',
        expand: true
      })
      for (let oldSession of bidSession) {
        let oldCandidates = allCandidates.filter(it => !groupedIds.includes(it.id) && it.sessionId === oldSession.sessionId);
        if (oldCandidates.length == 0) continue;
        let oldCandidateIds = oldCandidates.map(it => it.id);
        groupedIds = [...groupedIds, ...oldCandidateIds];
        this.bidSessions.push({
          key: `oldSession_${oldSession.sessionId}`,
          candidates: oldCandidates,
          totalCount: oldCandidates.length,
          data: oldSession,
          desc: this.getOldSessionDesc(oldSession),
          expand: false
        })
      }
    }
    const listDeclinedCandidates = this.candidates?.filter(it => this.isCarrierRefusedBid(it));
    this.bidSessions.push({
      key: 'declined',
      candidates: listDeclinedCandidates,
      totalCount: listDeclinedCandidates.length,
      expand: false
    })
    this.getBestCandidate();
  }

  // Chỉ update lại thông tin của candidate, không group lại theo session
  updateSessionCandidatesWithoutGroup() {
    this.bidSessions = this.bidSessions.map(session => {
      let curCandidateIds = session.candidates.map(it => it.id);
      let newCandidates = this.candidates.filter(it => curCandidateIds.includes(it.id));
      return {
        ...session,
        candidates: newCandidates,
      }
    })
    this.getBestCandidate();
  }

  getSessionLabel(session) {
    switch (session.key) {
      case 'newest': return 'Newest';
      case 'declined': return 'Declined';
      default: return 'Old Session';
    }
  }

  getOldSessionDesc(data) {
    let type = data?.type;
    if (!type) return '';
    let text = '';
    // if (type === 'carrierUnassigned') {
    //   text = 'Carrier unassigned';
    //   let oldCarrierId = data?.oldData?.replace('CARRIER_', '');
    //   if (oldCarrierId) {
    //     let oldCandidate: any = this.candidates.find(it => it.carrierId === oldCarrierId);
    //     if (oldCandidate) {
    //       text = `${text} ${oldCandidate.name || ''}`;
    //     }
    //   }
    // }
    if (type === 'vehicleTypeUpdated') {
      text = 'Old Equipment';
      let oldEquipment = this.getEquipment(data?.oldData);
      if (oldEquipment) {
        text = `${text} ${oldEquipment}`;
      }
    }
    return text;
    // let timeWhen = DateUtil.formatDate(data?.whenBy?.when, 'MMM DD, hh:mm A');
    // return `${text} - ${timeWhen}`;
  }

  private getEquipment(vehicle) {
    if (!vehicle) return 'N/A';
    if (vehicle.options?.length) {
      return `${vehicle.name} /w ${vehicle.options.map(Utils.capitalize).join(", ")}`
    }
    return vehicle.name
  }

  showBasePrice() {
    return `${this.$asMoney(this.bid?.basePrice)} base rate`;
  }

  public getLastAnswer(item) {
    let answers = item?.bidAnswers || [];
    if (answers.length) return answers[answers.length - 1];
    return {};
  }

  public hasCounterBidHistory(item) {
    if (item?.bidAnswers && Utils.isArrayNotEmpty(item?.bidAnswers)) return true;
    return false;
  }

  public isBestCarrierAnswer(item, idx) {
    let answer = item?.bidAnswers?.[idx];
    let numOfAnswers = item?.bidAnswers?.length || 0;
    if (answer?.entity === 'carrier' && idx >= numOfAnswers - 2) return true;
    return false;
  }

  // Chỉ show 2 answer cuối (1 warp, 1carrier)
  // Chỗ này cần show fulll history. Tại sao lại chỉ show show2 cái cuối thôi???
  public shouldShowItemHistory(item, idx) {
    if (!item?.bidAnswers?.length) return false;
    // return idx >= item.bidAnswers.length - 2;
    return true;
  }

  public getAuthorOfBidCounter(couterItem) {
    if (couterItem?.entity == 'carrier') return 'Carrier';
    if (couterItem?.entity == 'admin') return 'Warp';
    return 'N/A'
  }

  public checkCanCounter(item) {
    if (this.isBidCounterLocked && !this.isUserLockedBidCounter) return false;
    if (this.bid?.job?.assignedCarrier?.carrierId) return false;
    if (item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.waitAdminReply) return true;
    if (item?.status === 'un_registered') return false;
    if (item?.price && !item?.bidCounterOffer) return true;
    return false;
  }

  public checkCanHelpCarrierCounter(item) {
    if (this.bid?.job?.assignedCarrier?.carrierId) return false;
    if (item?.bidCounterOffer?.status && item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.waitCarrierReply) return true;
    return false;
  }

  public isCarrierAcceptedPrice(item) {
    return (item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.accepted)
  }

  public formatDate(date: string) {
    if (!date) return '';
    return DateUtil.formatDate(date, Const.FORMAT_GUI_DATETIME_V3) + ' ';
  }

  public getTooltipForBidCounterAction(counter) {
    return `${this.formatDate(counter?.update?.when)}${counter?.entityName ? 'by ' + counter.entityName : ''}`
  }

  public onBtnCounterBidForCarrier(item) {
    DialogService.openFormDialog1(CounterForCarrierComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.bid,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.onReload.emit();
        },
      },
      nzClassName: "modal",
    });
  }

  public onOpenCounterDialog(item) {
    DialogService.openFormDialog1(EditCounterComponent, {
      nzComponentParams: {
        carrierBid: item,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.onReload.emit();
        }
      },
    })
  }

  gotoDispatch() {
    return `${Const.routeAdminDispatchList}/${this.bid.jobId}`
  }

  onBtnAcceptBid(carrier: any) {
    let cost = this.bid.baseRate?.grandTotal === carrier.price ? this.bid.baseRate : this.carrierSaleDetailService.makeCost(carrier)
    DialogService.openFormDialog1(AssignCarrier, {
      nzComponentParams: {
        jobId: this.bid.jobId,
        isRequireCarrierAcceptLoadTender: this.bid?.isRequireCarrierAcceptLoadTender ?? false,
        matchedCarrier: carrier,
        cost: cost,
        job: this.bid?.job,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.onReload.emit();
          this.showDialog(`Carrier has been assigned successfully.<br /><br />
            <a href="${this.gotoDispatch()}" target="_blank">Go to dispatch</a>
          `);
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  getCandidateLastestHistory(item) {
    const { histories = [] } = item;
    if (!histories?.length) return '';

    const lastestHistory = histories.slice(-1)[0];
    return lastestHistory;
  }

  isCandidateUnassigned(item) {
    const lastestHistory = this.getCandidateLastestHistory(item);
    if (lastestHistory?.action === 'unassign') return true;
    return false;
  }

  onBtnSendLostBid(item) {
    this.confirmYesNo(`Are you sure to send email lost bid to carrier?`, () => {
      const url = `${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/send-lost-bid`;
      this.api.POST(url, { carrierId: item.carrierId }).subscribe(
        (resp) => {
          this.onReload.emit();
          this.showSuccess('Send email successfull');
        },
        (err) => {
          this.showErr(err);
        }
      );
    });
  }

  onExpandChange(item) {
    if (!item) return;
    let id = item?.id;
    if (this.expandSet.has(id)) this.expandSet.delete(id);
    else this.expandSet.add(id);
  }

  fetchCarriersBiddingScore(carriers) {
    const totalShipmentCost = InputHelper.getValueMoney(this.bid?.job?.totalShipmentsCost) || 0;
    let params = {
      bidId: this.bid?.id,
      carrierInfos: []
    }
    // Tinhs margin từng carrier
    for (let carrier of carriers || []) {
      if (!carrier.price) continue;
      let paramItem: any = {
        carrierId: carrier.carrierId,
      }
      if (totalShipmentCost > 0) {
        let margin = 1 - carrier.price/totalShipmentCost;
        paramItem.margin = margin;
      }
      params.carrierInfos.push(paramItem);
    }
    const url = `${Const.API_SERVICE_CARRIER_RATING}/carrier-rating/get-carriers-biding-score`;
    this.api.POST(url, params).subscribe(
      resp => {
        this.biddingScores = resp?.data;
        this.bestScoreCarrierId = '';   // reset lại bestScore
      },
      err => {}
    )
  }

  showBiddingScore(item) {
    if (this.biddingScores[item?.carrierId] >= 0) {
      return this.biddingScores[item?.carrierId].toFixed(1);
    }
    return 'N/A';
  }

  isBestScore(item) {
    if (!this.bestScoreCarrierId) {
      this.bestScoreCarrierId = this.getBestScoreCarrier();
    }
    return this.bestScoreCarrierId === item.carrierId;
  }

  getBestScoreCarrier(): string {
    const session = this.bidSessions.find(it => it.key === 'newest');
    if (!session) return '';
    const candidates = session.candidates || [];
    let max_score = 0;
    let bestCarrierId = '';
    for (let candidate of candidates) {
      let candidateScore = this.biddingScores?.[candidate.carrierId] || 0;
      if (candidateScore > max_score) {
        max_score = candidateScore;
        bestCarrierId = candidate.carrierId;
      }
    }
    return bestCarrierId;
  }

  getBestCandidate() {
    this.bestCandidateId = '';
    const newestSession = this.bidSessions.find(it => it.key === 'newest');
    if (!newestSession) return;
    const newestCandidates = newestSession.candidates || [];
    let min_price = 999999999;
    for (let candidate of newestCandidates) {
      if (candidate.price && candidate.price < min_price) {
        min_price = candidate.price;
        this.bestCandidateId = candidate.id;
      }
    }
  }
  isBestCandidate(item) {
    return this.bestCandidateId === item.id;
  }

  get isUserLockedBidCounter() {
    return this.authUser?.id && this.bid?.lockCounter?.byId === this.authUser?.id;
  }

  get isBidCounterLocked() {
    return this.bid?.lockCounter?.byId;
  }

  get counterLockedBy() {
    return this.bid?.lockCounter;
  }

  public isLoadingCounterLock = false;
  onUpdateCounterLock(type: 'lock' | 'unlock') {
    if (!this.bid?.id) {
      this.showErr('bidId is missing');
      return;
    }
    let confirmText = `Are you sure you want to ${type} counter session?`;
    if (type === 'lock') {
      confirmText = `Just only you can counter bid. ${confirmText}`;
    }
    this.confirmYesNo(confirmText, () => {
      this.isLoadingCounterLock = true;
      let url =  `${Const.APIURI_CARRIER_BIDS}/update_counter_lock_status`;
      const params = {
        bidId: this.bid.id,
        type
      }
      this.api.POST(url, params).subscribe(
        resp => {
          this.isLoadingCounterLock = false;
          this.showSuccess('Update counter lock success');
          this.onReload.emit();
        },
        err => {
          this.isLoadingCounterLock = false;
          this.showErr(err);
        }
      )
    })
  }

  isNeverBid(item) {
    return item?.metadata?.carrier?.neverBidBefore;
  }

  generateConfirmBidMsg() {
    const job = this.bid?.job;
    const stops = this.bid?.job?.stops || [];
    const buildMsgStop = (stop, index) => {
      let type, address, window;
      switch(stop.type) {
        case Const.TaskType.PICKUP:
          type = 'PU';
          break;
        case Const.TaskType.DROPOFF:
          type = 'DO';
          break;
        case Const.TaskType.RETURN:
          type = 'RE';
          break;
        default:
          type = '';
          break;
      }
      address = this.getAddressText(stop?.info?.addr);
      const appointment = stop?.info?.appointmentInfo;
      if(appointment?.from) {
        window = this.displayTimeWindow(appointment, stop?.info)
      } else {
        const timeWindow = stop?.info?.windows?.[0];
        if (timeWindow?.from) window = this.displayTimeWindow(timeWindow, stop?.info);
        else window = '';
      }
      let timezone = DateUtil.timezoneStandardToUsShort(stop?.info?.addr?.metadata?.timeZoneStandard)
      if(window) {
        window += ` (${timezone})`;
      }
      return `Stop ${index + 1}: ${type} ${address}
        ${window}
      `;
    }
    const code = job?.code;
    const equipmentCode = job?.requiredVehicle?.code;
    const allVehicle = MasterData.getAllVehicleTypes();
    const vehicle = allVehicle.find(v => v.code === equipmentCode);
    vehicle.options = job?.requiredVehicle?.options ?? [];
    const equipment = BizUtil.getVehicleName(vehicle);
    return `
      Hello, We are WARP.
      We have received your bid for the load ${this.bid?.pickupAddress?.city} - ${this.bid?.dropoffAddress?.city}

      Load details:
      Route ID: ${code}
      Equipment: ${equipment}
      ${stops.map(buildMsgStop).join('')}
    `
  }

  onBtnCopyConfirmBidMsg() {
    Utils.copyTextToClipboard(this.generateConfirmBidMsg(), () => {
      this.showSuccess(`Copied to the clipboard successfully.`);
    });
  }
}
