import { Injectable } from '@angular/core';
import { Const } from '@const/Const';
import { ApiService } from '@services/api.service';
import { BidCandidate } from '@wearewarp/types/data-model';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Const as WarpConst } from '@wearewarp/universal-libs';

@Injectable({
  providedIn: 'root'
})
export class BidSessionDetailService {
  private bidDataSubject = new Subject<void>();
  private sessionDetailSubject = new BehaviorSubject<any>(null);
  sessionDetail$ = this.sessionDetailSubject.asObservable();

  private bidDetailSubject = new BehaviorSubject<any>(null);
  bidDetail$ = this.bidDetailSubject.asObservable();

  private candidatesSubject = new BehaviorSubject<any[]>(null);
  candidates$ = this.candidatesSubject.asObservable();

  private bidsSubject = new BehaviorSubject<any[]>(null);
  bids$ = this.bidsSubject.asObservable();

  private carriersSubject = new BehaviorSubject<BidCandidate[]>(null);
  carriers$ = this.carriersSubject.asObservable();

  constructor(private api: ApiService) {
  }

  setSessionDetail(sessionDetail: any) {
    this.sessionDetailSubject.next(sessionDetail);
  }

  setCandidatesResponsed(candidates: any[]) {
    this.candidatesSubject.next(candidates);
  }

  // setBidDetail(bidDetail: any) {
  //   this.bidDetailSubject.next(bidDetail);
  // }

  setBids(bids: any[]) {
    this.bidsSubject.next(bids);
  }

  setCarriers(carriers: BidCandidate[]) {
    this.carriersSubject.next(carriers);
  }

  getDataObservable() {
    return this.bidDataSubject.asObservable();
  }

  emitGetDataBid() {
    this.bidDataSubject.next();
  }

  private carrierTabFilterSubject = new BehaviorSubject<any>(null);

  getCarrierTabFilter() {
    return this.carrierTabFilterSubject.asObservable();
  }

  setCarrierTabFilter(data) {
    this.carrierTabFilterSubject.next(data);
  }
}