<div class="dashboard-child-container no-padding list">
  <div>
    <nz-row >
      <nz-col [nzMd]="24">
        <div class="list-container">
          <div class="list-header">
            <div class="title">
              <p>Share this load to other marketplaces to reach more carriers.</p>
            </div>
          </div>

          <div class="list-body top20">
            <nz-table #nzTable nzBordered="true" 
              [nzData]="listRoutes" [nzLoading]="isLoading" [nzHideOnSinglePage]="true"
              [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'"
            >
              <ng-template #tplNoData>
                <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
              </ng-template>
              <ng-template #tplLoading>
                <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
              </ng-template>
              <thead>
                <tr>
                  <th nzLeft>Route</th>
                  <th nzLeft>Pickup Address</th>
                  <th nzLeft>Delivery Address</th>
                  <th nzLeft>Load Info</th>
                  <th nzLeft>
                    <img alt="third-party-logo" class="logo" [src]="getPartyLogo('truckstop')">
                  </th>
                  <th nzLeft>
                    <img alt="third-party-logo" class="logo" [src]="getPartyLogo('dat')">
                  </th>
                  <th nzLeft>
                    <img alt="third-party-logo" class="logo" [src]="getPartyLogo('truckerpath')">
                  </th>
                  <th nzLeft>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let post of nzTable.data; let i = index">
                  <tr>
                    <td>
                      {{ post?.jobCode }}
                    </td>
                    <td>
                      <div>
                        <div class="address">{{ getRouteOrigin(post) }}</div>
                        <div class="time-info">{{ getPickupTime(post) }}</div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="address">{{ getRouteDestination(post) }}</div>
                        <div class="time-info">{{ getDropoffTime(post) }}</div>
                      </div>
                    </td>
                    <td>
                      {{ getPostLoadInfo(post) }}
                    </td>
                    <td>
                      <nz-tag *ngIf="hasPost(post, 'truckstop')" [nzColor]="getPostStatusColor(post, 'truckstop')" [nz-tooltip]="getErrorMessage(post, 'truckstop')">
                        {{ getPostStatus(post, 'truckstop') }}
                      </nz-tag>

                      <div class="time-info" *ngIf="hasPost(post, 'truckstop')">
                        <div>Updated: {{ getPostUpdatedTime(post, 'truckstop') }}
                          <span *ngIf="post?.truckstop?.updateBy">- by {{ getFullName(post?.truckstop?.updateBy) }}</span>
                        </div>
                      </div>

                      <div class="post-group-action" *ngIf="hasPost(post, 'truckstop')">
                        <button nz-button nzType="text" [disabled]="isClosedPost(post)" nzDanger (click)="onBtnDeletePost(post?.truckstop, tplTitle)" nz-tooltip nzTooltipTitle="Delete post">
                          <i nz-icon nzType="delete" nzTheme="outline"></i>
                        </button>
                        <button nz-button nzType="text" [disabled]="isClosedPost(post) || isCreateError(post)" (click)="onBtnEditPost(post?.truckstop)" nz-tooltip nzTooltipTitle="Edit post">
                          <i nz-icon nzType="edit" nzTheme="outline"></i>
                        </button>
                        <button nz-button nzType="text" [disabled]="isClosedPost(post) || isCreateError(post)" (click)="onBtnRefreshPost(post?.truckstop, i)" [nzLoading]="isRefreshingPostId(post?.truckstop?.id)" nz-tooltip nzTooltipTitle="Refresh this post">
                          <i nz-icon nzType="reload" nzTheme="outline"></i>
                        </button>
                      </div>
                    </td>
                    <td>
                      <nz-tag *ngIf="hasPost(post, 'dat')" [nzColor]="getPostStatusColor(post, 'dat')" [nz-tooltip]="getErrorMessage(post, 'dat')">
                        {{ getPostStatus(post, 'dat') }}
                      </nz-tag>

                      <div class="time-info" *ngIf="hasPost(post, 'dat')">
                        <div>Updated: {{ getPostUpdatedTime(post, 'dat') }}
                          <span *ngIf="post?.dat?.updateBy">- by {{ getFullName(post?.dat?.updateBy) }}</span>
                        </div>
                      </div>

                      <div class="post-group-action" *ngIf="hasPost(post, 'dat')">
                        <button nz-button nzType="text" [disabled]="isClosedPost(post)" nzDanger (click)="onBtnDeletePost(post?.dat, tplTitle)" nz-tooltip nzTooltipTitle="Delete post">
                          <i nz-icon nzType="delete" nzTheme="outline"></i>
                        </button>
                        <button nz-button nzType="text" [disabled]="isClosedPost(post) || isCreateError(post)" (click)="onBtnEditPost(post?.dat)" nz-tooltip nzTooltipTitle="Edit post">
                          <i nz-icon nzType="edit" nzTheme="outline"></i>
                        </button>
                        <button nz-button nzType="text" [disabled]="isClosedPost(post) || isCreateError(post)" (click)="onBtnRefreshPost(post?.dat, i)" [nzLoading]="isRefreshingPostId(post?.dat?.id)" nz-tooltip nzTooltipTitle="Refresh this post">
                          <i nz-icon nzType="reload" nzTheme="outline"></i>
                        </button>
                      </div>
                    </td>
                    <td>
                      <nz-tag *ngIf="hasPost(post, 'truckerpath')" [nzColor]="getPostStatusColor(post, 'truckerpath')" [nz-tooltip]="getErrorMessage(post, 'truckerpath')">
                        {{ getPostStatus(post, 'truckerpath') }}
                      </nz-tag>

                      <div class="time-info" *ngIf="hasPost(post, 'truckerpath')">
                        <div>Updated: {{ getPostUpdatedTime(post, 'truckerpath') }}
                          <span *ngIf="post?.truckerpath?.updateBy">- by {{ getFullName(post?.truckerpath?.updateBy) }}</span>
                        </div>
                      </div>

                      <div class="post-group-action" *ngIf="hasPost(post, 'truckerpath')">
                        <button nz-button nzType="text" [disabled]="isClosedPost(post)" nzDanger (click)="onBtnDeletePost(post?.truckerpath, tplTitle)" nz-tooltip nzTooltipTitle="Delete post">
                          <i nz-icon nzType="delete" nzTheme="outline"></i>
                        </button>
                        <button nz-button nzType="text" [disabled]="isClosedPost(post) || isCreateError(post)" (click)="onBtnEditPost(post?.truckerpath)" nz-tooltip nzTooltipTitle="Edit post">
                          <i nz-icon nzType="edit" nzTheme="outline"></i>
                        </button>
                        <button nz-button nzType="text" [disabled]="isClosedPost(post) || isCreateError(post)" (click)="onBtnRefreshPost(post?.truckerpath, i)" [nzLoading]="isRefreshingPostId(post?.truckerpath?.id)" nz-tooltip nzTooltipTitle="Refresh this post">
                          <i nz-icon nzType="reload" nzTheme="outline"></i>
                        </button>
                      </div>
                    </td>
                    <td>
                      <button nz-button nzType="primary" (click)="onBtnCreatePost(post)">Create Post</button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
          </div>
        </div>
      </nz-col>
    </nz-row>
  </div>
</div>

<ng-template #tplTitle>
  <i nz-icon nzType="exclamation-circle" nzTheme="outline" class="danger"></i>
  <span class="left10">Delete post?</span>
</ng-template>
