import { Component, ViewChild } from "@angular/core";
import { ExtendValidators } from "@app/admin/base/validator";
import { BaseFormItem } from "@app/admin/base/form-item";
import { DateUtil } from "@services/date-utils";
import { AddRouteComponent } from "../component/add-route";
import { getDashboard } from "@services/index";
import { Const } from "@const/Const";

@Component({
  selector: "bid-session-create",
  templateUrl: "./index.html",
  styleUrls: ["./style.scss"],
})
export class BidSessionCreateComponent extends BaseFormItem {
  @ViewChild('formFilter') formFilter: AddRouteComponent;

  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: "Name", required: true },
    descLong: { label: "Description", required: true },
    descShort: { label: "Short Description", required: true },
    timeFrame: { label: "Time Frame", type: 'formGroup', required: true, childItem: {
      range: {label: '', required: true, placeHolder: ['Start date time', 'End date time'], validators: ExtendValidators.validateTimeWindow},
      timezone: { label: "Timezone", required: true, initialValue: 'EST' },
    }},
    routeInfo: { label: "", type: "formGroup", childItem: AddRouteComponent.declaration }
  };

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  timezones = DateUtil.listUsTimezones.map(it => DateUtil.usTimezoneShortDesc(it));

  protected subscribeTopbarTitle(fn: Function) {
    return fn("Create Bid Session");
  }

  routes: any[] = [];
  addRoute(job) {
    const routes = [...this.routes];
    const exist = routes.find(it => it.id === job.id);
    if(exist) return;
    routes.push(job);
    this.routes = [...routes];
  }

  removeRoute(job) {
    const routes = this.routes.filter(it => it.id !== job.id);
    this.routes = [...routes];
  }

  get selectedJobIds() {
    return this.routes.map(it => it.id);
  }

  get shouldCreateBidding(): boolean {
    const routeInfo = this.formFilter?.formData_JSON(true);
    const vehicleTypes = routeInfo?.vehicleTypes || [];
    const markets = routeInfo?.markets || [];
    const isAutoAddRoute = routeInfo?.isAutoAddRoute || false;
    if(isAutoAddRoute && (!markets.length || !vehicleTypes.length)) return false;
    return super.needUpdate;
  }

  private getTime(ranges: any[], timezone: string) {
    let fromTime = ranges[0];
    let toTime = ranges[ranges.length - 1];
    fromTime = DateUtil.convertLocalTime(DateUtil.toBeginOfDay(fromTime), timezone)?.toISOString();
    toTime = DateUtil.convertLocalTime(DateUtil.toEndOfDay(toTime), timezone)?.toISOString();
    return { fromTime, toTime };
  }

  get settings() {
    const routeInfo = this.formFilter?.formData_JSON(true);
    return routeInfo;
  }

  isSubmitting: boolean = false;
  onSubmit() {
    const data = this.getFormData_JSON(true);
    const routeInfo = this.formFilter.formData_JSON(true);
    const shortTimezone = data?.timeFrame?.timezone;
    const timezone = DateUtil.mapTimezoneUS(shortTimezone);
    const { fromTime, toTime } = this.getTime(data?.timeFrame?.range, timezone);
    const clientIds = routeInfo?.clientIds || [];
    const vehicleTypes = routeInfo?.vehicleTypes || [];
    const markets = routeInfo?.markets || [];
    const isAutoAddRoute = routeInfo?.isAutoAddRoute || false;

    const params = {
      name: data.name,
      descShort: data.descShort,
      descLong: data.descLong,
      fromTime,
      toTime,
      timeZone: timezone,
      autoAddRoute: isAutoAddRoute,
      clientIds, vehicleTypes, markets,
      jobIds: this.selectedJobIds
    }

    this.isSubmitting = true;
    this.api.POST(`${Const.APIURI_BID_SESSIONS}/create`, params).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.showInfo(`Bid session ${data.name} has been created successfully`);
        this.router.navigate([Const.routeAdminBidSessions, resp?.data?.id]);
      },
      (err) => {
        this.isSubmitting = false;
        this.showErr(err);
      }
    )
  }

  onCancel() {
    this.router.navigate([this.routeAdminCarrierSales], { queryParams: { bidStage: 'bidding_session' } });
  }
}