import { BaseComponent } from "@abstract/BaseComponent";
import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { BidCandidate, Contact } from "@wearewarp/types/data-model";
import dayjs from "dayjs";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { DialogService } from "@dialogs/dialog.service";
import { EditCarrierBidAnswerComponent } from "@app/admin/carrier-bids/components/edit-carrier-bid-answer/edit-carrier-bid-answer.component";
import { EditCarrierLookups } from "@app/admin/carrier-bids/components/detail-carrier-bid/edit-carrier-lookups-dialog";
import { NoteList } from "../notes";


@Directive()
export class BaseCarrierSaleTabResult extends BaseComponent {
  constructor() {
    super();
  }
  
  @Input() bid: any;
  @Input() contactInfos: any;
  @Input() candidates: (BidCandidate & {
    noteCount: number,
    goHighWayLink?: string,
    goHightWayClassifications?: string,
    goHightWayStatus?: string
    countOfJob: number,
    countOfJobRateLike: number,
    countOfJobRateDislike: number,
    lastCompleteJob?: any,
    countLane: number,
    countJobUnassigned: number,
    lastCost?: any,
    contacts: Array<Contact & {
      contactEmail?: string,
      contactPhone?: string,
    }>,
    lastSentSms?: any,
    lastSentEmail?: any,
  })[] = [];
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  get hasDedicatedPool(): boolean {
    return !!this.bid.pools?.length
  }

  onRefresh() {
    this.onReload.emit();
  }

  isTruckSearch(item) {
    return item.requestId && (item.rawSource == 'truckSearch' || item.source?.category == 'EXTERNAL_MATCHING');
  }

  isEmailSending: boolean;
  sendInviteRegisterToCarrierPublic(item) {
    if (this.isEmailSending) return;
    this.isEmailSending = true;
    let url = `${Const.APIURI_CARRIER_BIDS}/send-register-invite-bid-public`;
    let data = { logId: item.requestId }
    this.api.POST(url, data).subscribe(
      resp => {
        this.showSuccess('Sent successfully');
        this.isEmailSending = false;
        this.onRefresh();
      }, err => {
        this.showErr(err);
        this.isEmailSending = false;
      }
    )
  }

  sendInviteRegisterToCarrier(item) {
    if (!this.bid?.id) {
      this.showErr('bidId is undefined');
      return;
    }
    this.isEmailSending = true;
    let url = `${Const.APIURI_CARRIER_LOOKUP}/${this.bid.id}/${item.requestId}/send-register-invite`;
    let params = {}
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.isEmailSending = false;
        this.onRefresh();
        this.showSuccess('Sent successfull');
      },
      (err) => {
        this.isEmailSending = false;
        this.showErr(err);
      }
    )
  }

  getLastSendInvite(carrier) {
    const inviteHistories = carrier?.inviteHistories || [];
    const when = inviteHistories[inviteHistories?.length - 1]?.whenBy?.when;
    if (when) {
      return new Date(when).toLocaleString();
    } else {
      return ''
    }
  }

  isCarrierDNU(item) {
    return item.status == Const.CarrierStatus.DNU || item.status == Const.CarrierStatus.deactivate;
  }

  $asCarrierStatusText = (status) => {
    const statusKey = Object.keys(Const.CarrierStatus).find((key) => Const.CarrierStatus[key] == status) || "";
    const text = this.getStatusCarrier(status);
    return text || this.capitalizeFirstLetter(statusKey);
  };
  $asMoney = (money) => {
    return InputHelper.formatMoney1(money + '');
  };
  $formatDate = (date) => {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }
  $displayEstTime = (date) => {
    return dayjs(date).tz('America/New_York').format('MMM DD, hh:mm A');
  }
  $formatBidPublicDate = (isoStr) => {
    return DateUtil.displayLocalTime(isoStr, { format: Const.FORMAT_GUI_DATETIME_SHORT });
  }
  
  public isCarrierRefusedBid(item) {
    return item?.state == Const.CarrierBidState.Refused;
  }

  public getCarrierAcceptedPrice(item) {
    return item?.price
  }

  public isAdminAssignCarrier() {
    return this.bid?.job?.assignedCarrier?.carrierId;
  }

  onBtnEditItem(item) {
    if (item.carrierId) {
      DialogService.openFormDialog1(EditCarrierBidAnswerComponent, {
        nzComponentParams: {
          carrierBidItem: item,
          carrierBidInfo: this.bid,
          closeOnSuccess: true,
          cusSuccessMes: 'Please review in Response Received tab',
          updateSuccess: (resp) => {
            this.onReload.emit();
          },
        },
        nzClassName: "modal",
      });
    } else if (this.isTruckSearch(item)) {
      DialogService.openFormDialog1(EditCarrierLookups, {
        nzComponentParams: {
          type: 'answer',
          carrierBidItem: {
            id: item?.requestId,
            answer: {
              price: item.price,
              state: item.state
            }
          },
          carrierBidInfo: this.bid,
          closeOnSuccess: true,
          cusSuccessMes: 'Please review in Response Received tab',
          updateSuccess: (resp) => {
            this.onReload.emit();
          }
        }
      })
    }
  }

  get isPopulated() {
    return this.bid?.job?.shipments?.length;
  }

  get isMarketplace() {
    return this.bid?.job?.type === WarpConst.JobType.ghost && this.bid?.job?.source == WarpConst.JobSources.marketplace;
  }

  onBtnEditNote(item) {
    //
    if (item.carrierId) {
      this.drawerService.create({
        nzTitle: `Carrier: ${item.name}`,
        nzContent: NoteList,
        nzWidth: 500,
        nzContentParams: {
          bidId: this.bid.id,
          carrierId: item.carrierId,
          routeCode: this.bid.jobCode,
          carrierName: item.name,
          carrierNote: item.note
        }
      })
    } else if (this.isTruckSearch(item)) {
      DialogService.openFormDialog1(EditCarrierLookups, {
        nzComponentParams: {
          type: 'note',
          carrierBidItem: {
            id: item?.requestId,
            note: item?.note,
            answer: {
              price: item.price,
              state: item.state
            }
          },
          carrierBidInfo: this.bid,
          closeOnSuccess: true,
          updateSuccess: (resp) => {
            this.onReload.emit();
          }
        }
      })
    }
  }

  whyAdded(item) {
    if (!item?.metadata?.source) return;
    let text = '';
    switch (item.metadata.source) {
      case 'GEO_FEATURE_ASSIGNED':
        text += `This carrier was assigned to same lane in the past. LOAD: ${item.metadata.loadCode}.`;
        break;
      case 'GEO_FEATURE_PLACED':
        text += `This carrier placed bid to same lane in the past. LOAD: ${item.metadata.loadCode}.`;
        break;
      case 'EXTERNAL_MATCHING':
        text += `This carrier was matched by external system. Source: ${item.metadata?.searching?.source}`;
        break;
      case 'SAME_MARKET':
        text += `This carrier placed bid to same MARKET in the past. Source: ${item.metadata?.searching?.market}. LOAD: ${item.metadata?.loadCode}`;
        break;
      case 'PREFERRED_LANE':
        text += `This carrier has set this lane in the profile. Lane: ${item.metadata?.searching?.from?.city}, ${item.metadata?.searching?.from?.state} -> ${item.metadata?.searching?.to?.city}, ${item.metadata?.searching?.to?.state}`;
        break;
      default:
        text += item.metadata.source;
    }
    if (item.metadata?.searching?.radius) {
      text += ` Radius: ${item.metadata?.searching?.radius} miles.`
    }
    return text;
  }

  isUnsubcribed(data) {
    if (!data) return false;
    const check = this.contactInfos?.find(it => it.origin == data);
    return !!(check && check?.isUnsubscribed);
  }

  tooltipBaseRate(item): string {
    return `Lowest rate for the same lane & equipment. Based on the route ${item?.metadata?.baseRateInfo?.jobCode}`
  }

}