<div>
  <div class="lock-counter">
    <button class="right10" nz-button nzType="primary" (click)="onBtnCopyConfirmBidMsg()">Copy Confirm Message</button>
    <div *ngIf="!isBidCounterLocked">
      <button nz-button nzType="primary" nzTheme="outline" (click)="onUpdateCounterLock('lock')" [nzLoading]="isLoadingCounterLock">Lock Counter & Assign</button>
    </div>
    <div *ngIf="isBidCounterLocked" class="flex" style="gap: 14px;">
      <div>
        <warp-when-by [model]="counterLockedBy" [viewTemplate]="tplLockCounterBy"></warp-when-by>
        <ng-template #tplLockCounterBy let-hasData="hasData" let-displayInfo="displayInfo">
          <div class="text-secondary" style="max-width: 300px;">Counter & Assign Carrier actions are only available for carrier sale rep: {{displayInfo.createdBy}}</div>
          <div class="small-text top5">Locked at {{displayInfo.createdWhen}}</div>
        </ng-template>
      </div>
      <button nz-button *ngIf="isUserLockedBidCounter || isSysAdmin" nzType="primary" nzTheme="outline" (click)="onUpdateCounterLock('unlock')" [nzLoading]="isLoadingCounterLock">Unlock</button>
    </div>
  </div>
  <div *ngFor="let bidSession of bidSessions" class="collapse-session">
    <nz-collapse>
      <nz-collapse-panel
        [nzHeader]="tplCollapseHeader"
        [(nzActive)]="bidSession.expand"
        [nzShowArrow]="false"
      >
        <ng-template #tplCollapseHeader>
          <div class="flex flex-space-between" style="width: 100%;">
            <div class="session-header">
              <div class="session-icon">
                <i *ngIf="bidSession.expand" class="fa fa-fw fa-angle-down"></i>
                <i *ngIf="!bidSession.expand" class="fa fa-fw fa-angle-up"></i>
              </div>
              <div class="session-title left5">{{ getSessionLabel(bidSession) }} ({{ bidSession.totalCount || 0}})</div>
              <div class="session-desc left5">{{ bidSession?.desc || '' }}</div>
            </div>
          </div>
        </ng-template>

        <div class="group-session-table" [ngClass]="{collapsed: bidSession.expand ? false : true}">
          <nz-table #nzTable
            nzBordered="true"
            [nzData]="bidSession.candidates" nzSize="small"
            [nzFrontPagination]="false"
            [nzNoResult]="tplNoData"
            [nzScroll]="{ x: 'scroll' }"
          >
          <ng-template #tplNoData>
            <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
          </ng-template>
          <thead >
            <tr>
              <th nzLeft nzColumnKey="name" [nzWidth]="'238px'">
                Carrier Name
              </th>
              <th nzLeft nzColumnKey="pool" *ngIf="hasDedicatedPool" [nzWidth]="'155px'">
                Dedicated Pool
              </th>
              <th nzLeft nzColumnKey="contacts" [nzWidth]="'200px'">
                Carrier Contact
              </th>
              <th [nzWidth]="'220px'">Performance</th>
              <th [nzWidth]="'150px'">Carrier Score<span class="small-text left5">(Beta)</span></th>
              <th [nzWidth]="'220px'">Bidding</th>
              <th [nzWidth]="'150px'">Action</th>
              <th nzRight nzColumnKey="note" [nzWidth]="'70px'">Note</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of bidSession.candidates; let i = index">
              <tr (click)="onExpandChange(item)">
                <!-- Carrier Name -->
                <td nzLeft>
                  <a [routerLink]="[routeAdminCarrierList, item.carrierId]" target="_blank"  style="font-weight: 500; color: #000000d2;">
                    {{item.name || 'N/A'}}
                    <span nz-icon nzType="down" nzTheme="outline"
                      style="margin-left: 8px; padding-right: 10px;"
                      nz-tooltip [nzTooltipTitle]="titleTemplate"
                      [nzTooltipTitleContext]="{ $implicit: item }"
                      nzTooltipPlacement="bottomRight"
                      nzTooltipColor="#ffffff"
                      nzTooltipOverlayClassName="group-carrier-info"
                    ></span>
                    <ng-template #titleTemplate let-info>
                      <div class="carrier-name">
                        {{ info.name || 'N/A' }}
                      </div>
                      <nz-divider class="line"></nz-divider>
                      <div class="group-info">
                        <span>DOT: {{ item.dot || 'N/A' }}</span><br/>
                        <span>MC: {{ item.mc || 'N/A' }}</span><br/>
                        <span>Equipment: {{ item.equipment || 'N/A' }}</span>
                      </div>
                    </ng-template>
                  </a>

                  <br />

                  <ng-container *ngIf="item.status === 'un_registered'">
                    <nz-tag>Unregistered</nz-tag>

                    <ng-container *ngIf="this.isTruckSearch(item)">
                      <ng-container *ngIf="getLastSendInvite(item)">
                        <span class="f12" style="color: #8D8D8D;">Invitation sent</span> <span class="f12" style="color: #8D8D8D" nz-icon nzType="down" nzTheme="outline"
                            style="margin-left: 8px; padding-right: 10px;"
                            nz-tooltip [nzTooltipTitle]="lastSendTeamplate"
                            [nzTooltipTitleContext]="{ $implicit: item }"
                            nzTooltipPlacement="bottomRight"
                            nzTooltipColor="#ffffff"
                            nzTooltipOverlayClassName="group-carrier-info"
                        ></span>
                        <ng-template #lastSendTeamplate>
                          <p class="small-text" style="margin-top: 5px;" style="color: black" >when: {{getLastSendInvite(item)}}</p>
                        </ng-template>
                        <ng-container *ngIf="!getLastSendInvite(item)">
                          <button nz-button nzType="link" (click)="sendInviteRegisterToCarrier(item)" style="text-decoration: underline;">
                            {{item?.contacts?.[0]?.contactEmail ? 'Invite via Email' : 'Invite via SMS'}}
                          </button>
                        </ng-container>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!this.isTruckSearch(item)">
                      <ng-container *ngIf="item.lastSent">
                        <span class="f12" style="color: #8D8D8D">Invitation sent</span>
                      </ng-container>
                      <ng-container *ngIf="!item.lastSent">
                        <button nz-button nzType="link" (click)="sendInviteRegisterToCarrierPublic(item)">
                          Invite via Email
                        </button>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="item.status !== 'un_registered'">
                    <ng-container *ngIf="item.status != 1">
                      <nz-tag nzColor="warning">{{$asCarrierStatusText(item.status)}}</nz-tag>
                    </ng-container>
                    <ng-container *ngIf="item.status == 1">
                      <nz-tag nzColor="success">Active</nz-tag>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="isNeverBid(item)">
                    <nz-tag nzColor="purple">Rookie</nz-tag>
                  </ng-container>
                </td>
                <!-- Dedicated Pool -->
                <td nzLeft *ngIf="hasDedicatedPool">
                  <ng-container *ngIf="item.isDedicatedPool">
                    <a [routerLink]="[routeAdminPools, item.poolId]" target="_blank">{{item.poolName}}</a> <br />
                    Base Rate: <b>{{ item?.basePrice ? $asMoney(item.basePrice) : '-' }}</b>
                  </ng-container>
                  <ng-container *ngIf="!item.isDedicatedPool">-</ng-container>
                </td>
                <!-- Carrier Contact -->
                <td nzLeft>
                  {{item.contacts?.[0]?.contactName || 'N/A'}}<br />
                  <contact-info
                    [isFetch]="false"
                    owner="{{item.contacts?.[0]?.contactPhone}}"
                    type="phone"
                    [scopes]="['carrier_bid']"
                    [isUnsub]="isUnsubcribed(item.contacts?.[0]?.contactPhone)"
                  ></contact-info>
                  <contact-info
                    [isFetch]="false"
                    owner="{{item.contacts?.[0]?.contactEmail}}"
                    type="email"
                    [scopes]="['carrier_bid']"
                    [isUnsub]="isUnsubcribed(item.contacts?.[0]?.contactEmail)"
                  ></contact-info>
                </td>

                <!-- Performance -->
                <td>
                  <div *ngIf="!item.countOfJob">
                    <div class="group-lane">
                      N/A
                    </div>
                  </div>
                  <div *ngIf="item.countOfJob > 0">
                    <div>All lane: {{ item.countOfJob }}</div>
                    <div class="group-lane text-secondary top10">
                      <div class="rate-info" display-rate-info
                        [numOfLike]="item.countOfJobRateLike"
                        [numOfDislike]="item.countOfJobRateDislike"
                        [carrierId]="item.carrierId"
                      ></div>
                      <div class="flex">
                        <div class="left10 right10">|</div>
                        <div>This lane: {{ item.countLane || 0 }}</div>
                      </div>
                    </div>
                    <div *ngIf="item.countJobUnassigned" style="color: #faad14;" nz-tooltip nz-tooltip nzTooltipTitle="Carrier won the bids but couldn't commit to pickup">
                      <span nz-icon nzType="warning" nzTheme="outline"></span> Carrier Canceled: {{ item.countJobUnassigned }}
                    </div>
                  </div>
                </td>
                <!-- Carrier score -->
                <td>
                  <div class="flex">
                    <div>{{ showBiddingScore(item) }}<i nz-icon nzType="star" nzTheme="fill" class="left5"></i></div>
                    <div *ngIf="isBestScore(item)" class="flex left5">
                      <div class="arrow-item"></div>
                      <div class="best-score">Best</div>
                    </div>
                  </div>
                </td>
                <!-- Bidding -->
                <td>
                  <ng-container *ngIf="hasCounterBidHistory(item) && !isCarrierRefusedBid(item)">
                    <div class="flex">
                      <div>
                        <div *ngFor="let counter of item.bidAnswers; let i=index" style="text-align: left;">
                          <div *ngIf="shouldShowItemHistory(item, i)" class="flex">
                            <div nz-tooltip [nzTooltipTitle]="getTooltipForBidCounterAction(counter)" nzTooltipPlacement="rightTop">{{counter?.price ? $asMoney(counter.price): '-'}} by {{getAuthorOfBidCounter(counter)}}</div>
                            <div *ngIf="isBestCandidate(item) && isBestCarrierAnswer(item, i)" class="flex left15" style="align-self: self-end;">
                              <div class="arrow-item"></div>
                              <div class="best-score">Best</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!isAdminAssignCarrier() && !isCarrierAcceptedPrice(item) && bid?.isAllowPlaceBid" class="flex gap5 top5">
                      <div *ngIf="hasBasePrice" class="base-rate">{{showBasePrice()}}</div>
                      <button nz-button nzType="default" [disabled]="!checkCanCounter(item)" (click)="onOpenCounterDialog(item)" nzSize="small">Counter</button>
                      <ng-container *ngIf="item?.bidCounterOffer">
                        <button nz-button nzType="default" [disabled]="!checkCanHelpCarrierCounter(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer" nzSize="small" (click)="onBtnCounterBidForCarrier(item)"><img src="/assets/svg/question_answer.svg" alt=""></button>
                      </ng-container>
                      <ng-container *ngIf="!item?.bidCounterOffer">
                        <button nz-button  nzType="default" nzSize="small" (click)="onBtnEditItem(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer">
                          <img src="/assets/svg/question_answer.svg" alt="" style="margin-right: 3px;">
                        </button>
                      </ng-container>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!hasCounterBidHistory(item) && !isCarrierAcceptedPrice(item) && !isCarrierRefusedBid(item) && !isAdminAssignCarrier()">
                    <div class="flex flex-space-between">
                      <div *ngIf="hasBasePrice" class="base-rate">{{showBasePrice()}}</div>
                      <ng-container *ngIf="item.lastSent">
                        <nz-tag nzColor="warning">Waiting for a response</nz-tag>
                      </ng-container>
                      <button nz-button  nzType="default" nzSize="small" (click)="onBtnEditItem(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer">
                        <img src="/assets/svg/question_answer.svg" alt="" style="margin-right: 3px;">
                      </button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="isCarrierRefusedBid(item)">
                    <nz-tag nzColor="error" nz-tooltip [nzTooltipTitle]="formatDate(item?.update?.when)">Refused</nz-tag>
                  </ng-container>

                  <ng-container *ngIf="isCarrierAcceptedPrice(item)">
                    <nz-tag nzColor="success" nz-tooltip [nzTooltipTitle]="getTooltipForBidCounterAction(getLastAnswer(item))">
                      <span nz-icon nzType="check" nzTheme="outline"></span>
                      Accepted {{$asMoney(getCarrierAcceptedPrice(item))}}
                    </nz-tag>
                  </ng-container>
                </td>
                <!-- Action -->
                <td style="min-width: 150px;">
                  <div class="flex-column assign-item" *ngIf="!isBidCounterLocked || isUserLockedBidCounter">
                    <div *ngIf="item?.carrierId && item?.carrierId != bid?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                      <div *ngIf="item.status == 1 && (!isMarketplace || isPopulated)">
                        <button nz-button nzType="primary" (click)="onBtnAcceptBid(item)">{{ isCandidateUnassigned(item) ? 'Re-assign Carrier' :'Accept & Assign' }}</button>
                      </div>
                      <div *ngIf="item.status == 1">
                        <button *ngIf="!item.sentLostBid" nz-button nzSize="small" class="f12 top5" (click)="onBtnSendLostBid(item)">Send lost bid</button>
                        <div *ngIf="item.sentLostBid" class="top5">
                          <warp-when-by [model]="item.sentLostBid" [viewTemplate]="tplSentLostBid"></warp-when-by>
                          <ng-template #tplSentLostBid let-hasData="hasData" let-displayInfo="displayInfo">
                            <div class="small-text">Sent lost bid by {{displayInfo.createdBy}} on {{displayInfo.createdWhen}}</div>
                          </ng-template>
                        </div>
                      </div>
                      <div *ngIf="item.status == 1 && isMarketplace && isPopulated">
                        <img class="svg-icon truck1 right10" alt="" nz-tooltip
                          nzTooltipTitle="This carrier cannot be assigned because relate to Marketplace Order">
                      </div>
                      <div *ngIf="item.status !== 1">
                        <a nz-tooltip="This carrier cannot be assigned because it is not in Active state.">-</a>
                      </div>
                    </div>
                    <div
                      *ngIf="item?.carrierId && item?.carrierId == bid?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                      <span nz-typography nzType="success">
                        <span nz-icon nzType="check" nzTheme="outline"></span> Accepted
                        <p class="small-text">
                          <span>by: {{ getFullName(bid?.job?.assignedCarrier?.update?.byUser) || "N/A" }}</span>
                          <br />
                          <span>when: {{ $formatDate(bid?.job?.assignedCarrier?.update?.when) }}</span>
                        </p>
                      </span>
                    </div>
                    <div
                      *ngIf="isCandidateUnassigned(item)">
                      <span nz-typography nzType="warning">
                        <span nz-icon nzType="warning" nzTheme="outline"></span> Unassigned
                        <p class="small-text">
                          <span>by: {{ getFullName(getCandidateLastestHistory(item)?.whenBy) || "N/A" }}</span>
                          <br />
                          <span>when: {{ $displayEstTime(getCandidateLastestHistory(item)?.whenBy?.when) }} (EST)</span>
                        </p>
                      </span>
                    </div>
                  </div>
                  <div *ngIf="isBidCounterLocked && !isUserLockedBidCounter && i == 0s">
                    <span class="text-secondary">No permission</span>
                  </div>
                </td>
                <!-- Note -->
                <td nzRight style="width: 70px;">
                  <div *ngIf="!isAdminReadOnlyRole">
                    <ng-container *ngIf="item.noteCount">
                      <nz-badge nzSize="default" [nzCount]="item.noteCount">
                        <a (click)="onBtnEditNote(item)" nzSize="small">
                          <img src="/assets/svg/edit-button.svg" alt="">
                        </a>
                      </nz-badge>
                    </ng-container>
                    <ng-container *ngIf="!item.noteCount">
                      <a (click)="onBtnEditNote(item)" nzSize="small">
                        <img src="/assets/svg/edit-button.svg" alt="">
                      </a>
                    </ng-container>
                  </div>
                </td>
              </tr>

              <!-- Expand -->
              <tr *ngIf="expandSet.has(item.id)" style="background-color: #F7F7F8;">
                <td colspan="7">
                  <div carrier-sale-expand-more-candidate-infos [bid]="bid" [candidate]="item"></div>
                </td>
              </tr>
            </ng-container>
          </tbody>
          </nz-table>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</div>
