<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div *ngIf="!isLoading && !isError">
  <bid-session-outreach [bidSessionId]="bidSessionId" [currentCarrierIds]="data.carrierIds"></bid-session-outreach>
</div>
<div *ngIf="!isLoading && !isError">
  <div class="responsed-title">
    Responsed Carriers  
  </div>
  <div class="carrier-selection">
    <bid-session-carriers [bidSessionId]="bidSessionId"></bid-session-carriers>
  </div>
</div>