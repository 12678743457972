import { Component } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { Subscription } from "rxjs";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { EditCarrierBidSettings } from "@app/admin/components/carrier-bid/edit-settings";
import { Const } from "@const/Const";

@Component({
  selector: "carrier-sale-rate",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleRate extends BaseComponent {
  bid: any;
  jobHyperLink;
  displayInfo: any = {};
  private bidDetailSubscription: Subscription;
  onHold: string[] = []
  
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
      this.onProcessRouteInfo(bidDetail);
    });
  }
  getLabelType(key) {
    switch (key) {
      case Const.CarrierBidTypes.direct: return 'Auto assign when carrier accept load (Direct)';
      case Const.CarrierBidTypes.manual: return 'Manual review (Indirect)';
      default: return 'Manual';
    }
  }

  private getMileage(): string {
    const totalDistance = this.bid?.job?.totalDistance
    if (!totalDistance) return '';
    return (totalDistance / 1609.34).toFixed(2).toLocaleString();
  }

  private getTotalShipmentCostPerMile() {
    let mileage: any = this.getMileage();
    if (!mileage) return null;
    mileage = parseFloat(mileage);
    const totalShipmentCost = InputHelper.getValueMoney(this.bid?.job?.totalShipmentsNetCost)
    if (mileage && Utils.isNumber(totalShipmentCost)) {
      return `$${(totalShipmentCost / mileage).toFixed(2)}/mi`;
    }
    return null;
  }

  private onProcessRouteInfo(bidDetail) {
    this.displayInfo = {
      isGhostLoad: bidDetail?.job?.type === WarpConst.JobType.ghost,
      isMarketplace: bidDetail?.job?.type === WarpConst.JobType.ghost && bidDetail.job?.source == WarpConst.JobSources.marketplace,
      clientName: bidDetail?.job?.clients?.[0]?.name || 'N/A',
      numOfClient: bidDetail?.job?.clients?.length ?? 0,
      arrClientName: bidDetail?.job?.clients?.map(it => it.name) || [],
      tempRange: bidDetail?.job?.tempRange,
      numOfServiceOptions: bidDetail?.serviceOptions?.length ?? 0,
      firstServiceOptions: bidDetail?.serviceOptions?.[0]?.name ?? '',
      allServiceOptions: bidDetail?.serviceOptions?.map(it => it.name)?.join(', '),
      numOfOptionalEquipments: bidDetail?.optionalEquipments?.length ?? 0,
      firstOptionalEquipments: bidDetail?.optionalEquipments?.[0]?.label ?? '',
      allOptionalEquipments: bidDetail?.optionalEquipments?.map(it => it.label)?.join(', '),
      totalShipmentCost: bidDetail?.job?.totalShipmentsCost,
      totalShipmentsNetCost: bidDetail?.job?.totalShipmentsNetCost,
      bidModeType: this.getLabelType(bidDetail?.type),
      totalShipmentCostPerMile: this.getTotalShipmentCostPerMile(),
      warpOffer: Utils.isNumber(bidDetail?.basePrice) ? InputHelper.formatMoney2(String(bidDetail.basePrice)) : null,
    }
  }

  onBtnEditSettings() {
    DialogService.openFormDialog1(EditCarrierBidSettings, {
      nzComponentParams: {
        model: {
          type: this.bid?.type,
          isAllowPlaceBid: this.bid?.isAllowPlaceBid,
          isShowBasePrice: this.bid?.isShowBasePrice,
          basePrice: this.bid?.basePrice,
          isShowOnBidBoard: this.bid?.isShowOnBidBoard,
          isRequireCarrierAcceptLoadTender: this.bid?.isRequireCarrierAcceptLoadTender,
        },
        isSourceMarketPlace: this.displayInfo.isMarketplace,
        onSave: data => {
          let url = `${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/settings`;
          return this.api.PUT(url, data)
        },
        updateSuccess: resp => {
          this.carrierSaleDetailService.emitGetDataBid();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }
  

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }
 
}