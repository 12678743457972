import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import _ from 'underscore';
import { getDashboard } from "@services/index";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { BidSessionDetailService } from "@app/admin/bid-sessions/bid-session-detail.service";
import { Title } from "@angular/platform-browser";
import { StringULID } from "@wearewarp/types";
import { ResponseBidSessionDetail } from "@wearewarp/types/rest-api/admin/carrier-bid/bid-session";

@Component({
  selector: "bid-session-detail",
  templateUrl: "./index.html",
  styleUrls: ["./style.scss"],
})
export class BidSessionDetailComponent extends BaseDetail {

  public isLoading = false;
  public data: ResponseBidSessionDetail;
  // public bidId: string;
  public isError: boolean = false;
  protected get crudEntity(): string { return 'bid_sessions' }

  protected subscribeTopbarTitle(fn: Function) {
    return fn("View Bid Session");
  }

  private getDataSubscription: Subscription;

  constructor(
    private titleService: Title,
    private bidSessionDetailService: BidSessionDetailService,
    activatedRoute: ActivatedRoute,
    api: ApiService,
  ) {
    super(activatedRoute);
    this.api = api;
    this.activatedRoute.params.subscribe(params => {
      super.handleNavigationEnd(activatedRoute.toString(), activatedRoute.queryParamMap);
    })
  }

  public get bidSessionId() {
    return this.id
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getDataSubscription = this.bidSessionDetailService.getDataObservable().subscribe(() => {
      this.getData();
    });
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  ngOnDestroy(): void {
    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
    }
  }

  getData() {
    if (!this.bidSessionId) return;
    this.isLoading = true;

    let url = `${Const.APIURI_BID_SESSIONS}/${this.bidSessionId}`;
    this.api.GET(url).subscribe(
      resp => {
        this.data = resp.data;
        const bids = resp.data?.bids ?? [];
        const bidIds = bids.map(it => it.id);
        this.getCarrierResponsed(bidIds);
        this.titleService.setTitle(`${this.data?.name} - BID Session - WARP Admin`);
        this.bidSessionDetailService.setSessionDetail(this.data);
        // this.bidSessionDetailService.setBidDetail(groupBid);
        this.bidSessionDetailService.setBids(bids);
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
        this.isError = true;
      }
    );
  }

  private getCarrierResponsed(bidIds: StringULID[]) {
    // const urls = bidIds.map(bidId => `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/${bidId}/get_list_carriers?filter=${encodeURIComponent(JSON.stringify({ type: 'response-received' }))}&limit=-1`);
    // this.api.concurrentGET(urls).subscribe(
    //   res => {
    //     const candidates = res.map(r => r.data?.list_data || []).flat();
    //     this.bidSessionDetailService.setCandidatesResponsed(candidates);
    //   },
    //   error => {
    //     this.showErr(error);
    //   }
    // )
  }
}
