<ng-container *ngIf="!displayInfo.isGhostLoad">
  <div class="text-header">Rate</div>
  <div nz-row class="line-item">
    <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Gross Revenue</div>
    <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
      <span nz-tooltip nzTooltipTitle="Add up grand total for all shipments on the route"
        nzTooltipPlacement="right">
        {{ displayInfo.totalShipmentCost || 'N/A'}}
      </span>
    </div>
  </div>
  <div nz-row class="line-item">
    <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Net Revenue</div>
    <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
      <span nz-tooltip nzTooltipTitle="Denim fee has been deducted."
        nzTooltipPlacement="right">
        {{ displayInfo.totalShipmentsNetCost || 'N/A'}}
      </span>
    </div>
  </div>
  <div nz-row class="line-item">
    <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Rate / mile</div>
    <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">{{ displayInfo.totalShipmentCostPerMile || 'N/A' }}
    </div>
  </div>
</ng-container>